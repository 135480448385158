import * as React from "react"
//import TestimonialCard from "../components/testimonial-card"
//import useTestimonialsBlock from "../hooks/useTestimonialsBlock"
import { Swiper, SwiperSlide } from "swiper/react";
import { /* Navigation, */ Pagination, Autoplay, EffectFade, Keyboard } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
//import 'swiper/css/effectFade';



const Carusel = ({ items, className, fade = false }) => {

  //const items = useTestimonialsBlock()
  //const testimonials = items.filter(e => e.label)

  //if (testimonials.length === 0) return false

  return (

    <div className="">
      <Swiper
        modules={fade ? [Pagination, Autoplay, EffectFade, Keyboard] : [Pagination, Autoplay, Keyboard]}
        className={className}
        spaceBetween={0}
        slidesPerView={1}
        //navigation={isMobile ? false : true}
        pagination={{ clickable: true }}
        autoplay={{ delay: 2000, waitForTransition: false }}
        effect="fade"
        keyboard={true}  
      >
        {items.map((item, i) => (
          <SwiperSlide className="mb-12" key={i}>
            {item}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>

  )
}

export default Carusel
