import React from "react"
//import { Link } from "gatsby"


const FramePlaceholder = ({ children, flip = false, className = '', ...props }) => {


  return (

    <div className={"h-full flex flex-col justify-end " + className}>

      <div className="mx-auto">
        <div className=" w-fit	inline-block  px-0 py-0 lg:px-[20px] lg:py-[18px] mx-auto relative">

          <div className={"w-24 h-24  absolute top-0 right-0  -z-10 hidden lg:block " + (flip ? 'bg-yellow' : 'bg-blue')}></div>
          <div className={"w-24 h-24  absolute bottom-0 left-0 -z-10 hidden lg:block " + (flip ? 'bg-blue' : 'bg-yellow')}></div>

          <div className="bg-white">
            {children}
          </div>

        </div>
      </div>
    </div>

  )
}

export default FramePlaceholder