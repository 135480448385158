import React/* , { useState } */ from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import getMetatags from "../util/getMetatags"
import Seo from "../components/Seo"
import TwoColsContainer from "../components/two-cols-container"
import FramePlaceholder from "../components/frame-placeholder"
import {TextBoxH2} from "../components/text-box"
import Image from "../components/Image"
import Hero from "../components/hero"
import Hs from "../images/h-sperator.svg"
import Carusel from "../components/carusel"

function Basic2Page({ data: { node, section1BG }, ...props }) {

  const seo = getMetatags(node.metatag/* , nodeOverview.relationships.OGImage?.localFile?.publicURL */)
  const { id } = node
  const { hero, double, double2, single, twoHtml,freeText } = node.relationships

  const newLocal = <TwoColsContainer
    className="mt-12 lg:mt-16 bg-right-top"
    colsClassName=''
    col1ClassName="lg:w-1/2 h-full z-20"
    col2ClassName="lg:w-1/2 h-full z-10"
    bgImage={section1BG}
    col1={<FramePlaceholder flip={true} className="relative lg:left-[-36px] lg:bottom-[-10px]">
      <TextBoxH2
        className="px-4 py-4 lg:px-16 lg:py-24 lg:border shadow-lg text-justify"
        titleClassName={'font-bold text-right'}
        title={double.title}
        html={double.html.processed}
        htmlClassName="text-black" />
    </FramePlaceholder>}
    col2={<Carusel fade={true} items={double.relationships.images.map((e, i) => (
      <Image image={e} alt={double.images[i].alt} key={i} />
    ))} />} />
  //console.log(id, path)
  //console.log(freeText)

  return (
    <Layout id={`page-${id}`}>
      <Seo {...seo} />
      <Hero
        className="mt-[90px] lg:mt-[126px] h-[147px] lg:h-[350px]  "
        title={hero.title}
        subtitle={''}
        url={hero.relationships.image.localFile.publicURL}
        urlMobile={hero.relationships?.mobileImage?.localFile.publicURL}
      />
      {newLocal}


      {double2.title &&
        <TwoColsContainer
          className="my-12 lg:my-16 "
          colsClassName=""
          //bgImage={section1BG}
          col1={<div className="hidden lg:block">
            {double2.relationships.images.map((e, i) => (
              <>
                {/* <Image key={i} image={e} alt={double.images[i].alt} className="border"  /> */}
                <img src={e.localFile.publicURL} alt={double.images[i].alt} />
              </>
            ))}
          </div>}
          col2={
            <FramePlaceholder>
              <TextBoxH2
                className=" px-4 py-4 lg:px-20 lg:py-20 border shadow-lg text-justify"
                title={double2.title}
                titleClassName={'font-bold text-black lg:text-[26px]'}
                html={double2.html.processed}
                htmlClassName="user-text-list"
                showTitleDecoration={false}
              />
            </FramePlaceholder>
          }
        />
      }

      {twoHtml.title &&
        <section className="container px-1 lg:px-6 my-12">
          <FramePlaceholder flip={true}>
            <TwoColsContainer
              className="px-4 py-4 lg:px-14 lg:py-16 bg-white border shadow-lg"
              colsClassName='lg:space-x-14 lg:space-x-reverse'
              title={twoHtml.title}
              titleClassName="font-bold"
              col1={<div className="text-lg text-justify html-block" dangerouslySetInnerHTML={{ __html: twoHtml.html1.processed }} />}
              col2={<div className="text-lg  text-justify html-block" dangerouslySetInnerHTML={{ __html: twoHtml.html2.processed }} />}
            />
          </FramePlaceholder>
        </section>
      }

      {(single?.title || single?.relationships.image) &&
        <section className={"my-12 " + (!single.title ? ' lg:mt-24' : ' ')} id="block-4">
          <div className="container -max-w-[1200px] space-y-8">
            {single.title && <h2 className="text-[34px] text-blue font-bold"><img src={Hs} alt="" className="inline ml-1.5" />{single.title}</h2>}
            <Image image={single.relationships.image} className="w-full" />
          </div>
        </section>
      }

      {(freeText?.title || freeText?.html) &&
        <section className={"my-12 " + (!freeText.title ? ' lg:mt-24' : ' ')} id="block-4">
          <div className="container -max-w-[1200px] space-y-8">
            {freeText.title && <h2 className="text-[34px] text-blue font-bold"><img src={Hs} alt="" className="inline ml-1.5" />{freeText.title}</h2>}
            {freeText.html && <div className="text-lg html-block " dangerouslySetInnerHTML={{ __html: freeText.html.processed }} />}
          </div>
        </section>
      }

    </Layout>
  )
}

export default Basic2Page

/* export const Head = () => <title>Basic Page</title> */

export const query = graphql`
  query Basic2Query($id: String!) {
    node: nodeInnerPage2(id: { eq: $id }) {
      id
      title
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      relationships {
        hero: field_hero {
          ...Hero
        }
        double: field_paragraph {
          ...TitleHtmlImage
        }
        double2: field_paragraph1 {
          ...TitleHtmlImage
        }
        single: field_paragraph_single {
          ...TitleImage
        }
        twoHtml: field_paragraph_single1 {
          ...TwoHtmlBlocks
        },
        freeText: field_free_text {
          ...TitleHtml
        }
      }
    }
    section1BG: file(sourceInstanceName: {eq: "backgrounds"}, base: {eq: "section1-bg.svg"}) {
      base
      publicURL
    }
  }
`
